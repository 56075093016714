import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Typography, Carousel, Spin, Alert } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import propTypes from 'prop-types';
import './comments.css';

const { Title } = Typography;

function Comments() {
  // TODO: Add state to manage alert and make them disappear after 5 secs
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/comments`)
      .then(({ data }) => {
        setComments(data);
      })
      .catch(() => {
        setError(true);
        // console.error('There was an error retrieving comments: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} span={18}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20rem',
            }}>
            <Spin size="large" tip="Loading..." />
          </div>
        </Col>
      </Row>
    );
  }

  if (error) {
    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} span={18}>
          <Alert
            message="Sorry :("
            description="Comments seem to be unavailable right now. Kindly check later."
            type="error"
            showIcon
            closable
          />
        </Col>
      </Row>
    );
  }

  return (
    <section className="comments-section">
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} span={18}>
          <Carousel
            dots={false}
            arrows
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            autoplay
            autoplaySpeed={12000}>
            {comments.map((commentObj) => {
              const { comment, author, title } = commentObj;
              return (
                <div key={`${author}`}>
                  <Row justify="center" align="middle">
                    <Col span={20}>
                      <p>{comment}</p>
                      <Title level={5}>
                        {author},
                        <br />
                        {title}
                      </Title>
                    </Col>
                  </Row>
                </div>
              );
            }) ?? 'Loading Comments...'}
          </Carousel>
        </Col>
      </Row>
    </section>
  );
}

export default Comments;

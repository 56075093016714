import React, { useState, useEffect } from 'react';
import { Row, Typography, Col, Timeline } from 'antd';
import RightImageSection from '../../components/RightImageSection/RightImageSection';
// import LeftImageSection from '../../components/LeftImageSection/LeftImageSection';
import Comments from '../../components/Comments/Comments';

import './about.css';
// import bgSection from '../../assets/bg_section.jpg';
import labout from '../../assets/rsz_about_nobg.png';

const { Title } = Typography;

// const rIMGURL = bgSection;
// const rTitle = 'ABOUT THE BOOK';
// const rText = `Uncover moments where resilience, faith, and an abundance mindset collided to shape a narrative of triumph over adversity. Let this be an inspiration for girls to rise above limitations and embrace their full potential. Explore the depths of courage and perseverance as you navigate through the pages of empowerment, guided by Evelyne's remarkable life experiences.`;

const lIMGURL = labout;
const lTitle = 'ABOUT THE AUTHOR';
const lText = `Evelyne Mshai Mwakina, the storyteller behind 'Breaking the Barriers: Empowered Girl Child.' Explore her personal narrative and the invaluable lessons learned, empowering girls to overcome challenges with unwavering determination, hard work, and profound trust in God. Discover the untold stories that inspire a new generation of resilient and empowered young women. Evelyne's journey from humble beginnings in Taita Taveta County to her esteemed position as the Corporate Affairs and Communications Manager at the Kenya Broadcasting Corporation exemplifies her steadfast commitment to excellence and integrity.`;

function About() {
  // const [rSectionValues, setrSectionValues] = useState({
  //   imgUrl: rIMGURL,
  //   title: rTitle,
  //   text: rText,
  // });
  const [lSectionValues, setlSectionValues] = useState({
    imgUrl: lIMGURL,
    title: lTitle,
    text: lText,
  });

  const handleScroll = () => {
    const parallax = document.getElementById('about-landing');
    if (parallax) {
      const top = window.scrollY;
      parallax.style.backgroundPositionY = `${top * 0.03}rem`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section id="about-landing" className="about-landing">
        <Row justify="space-between" align="middle" style={{ height: '100vh' }}>
          <Col span={8}>
            <Title style={{ color: '#000', fontFamily: 'Cinzel Decorative' }}>
              MEET EVELYNE
            </Title>
          </Col>
        </Row>
      </section>
      <RightImageSection
        sectionValues={lSectionValues}
        setrSectionValues={setlSectionValues}
      />
      <Comments />
      <section className="time">
        <Row justify="space-around" align="middle">
          <Col span={16}>
            <Title
              level={2}
              style={{
                color: '#000',
                fontFamily: 'roboto',
                fontSize: '3rem',
              }}>{`EVELYNE'S JOURNEY`}</Title>
            <br />
            <Timeline
              mode="alternate"
              style={{
                color: '#000',
                fontSize: '2rem',
                alignItems: 'center',
              }}
              items={[
                {
                  color: 'black',
                  children: `Initiated KBC's in-house magazine, actively participated in anti-corruption activities, and served in various committees within and outside KBC.`,
                },
                {
                  children:
                    'Leadership management in China; Multimedia journalism perspectives in Rwanda; Gender-responsive policy in South Korea.',
                  color: 'black',
                },
                {
                  color: 'black',
                  children:
                    'Human rights workshops in Switzerland; Public service broadcasting training in Sweden.',
                },
                {
                  children: `Executive MBA, a Bachelor's degree in Philosophy and Literature, and a Post Graduate Diploma in Mass Communication.`,
                  color: 'black',
                },
                {
                  color: 'black',
                  children:
                    'Selected and edited news items, supervised news teams, and monitored global news trends at KBC',
                },
                {
                  children:
                    'Member of the Public Relations Society of Kenya, Proficient in English, Kiswahili, and Kitaita',
                  color: 'black',
                },
              ]}
            />
          </Col>
        </Row>
        {/* <Button type="primary" shape="round" size="large">
          DOWNLOAD RESUME
        </Button> */}
      </section>
      {/* <LeftImageSection
        sectionValues={rSectionValues}
        setrSectionValues={setrSectionValues}
      /> */}
    </>
  );
}

export default About;

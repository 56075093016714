import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, List, Space, Divider, Spin, Alert } from 'antd';
import axios from 'axios';
import Markdown from 'marked-react';
import { useArticles } from './BlogContext';

import './blog.css';

const { Title, Paragraph } = Typography;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// const SERVER_URL = 'http://localhost:3030';

function Blog() {
  // Fetch the latest 5 blogs written
  const { articles, setArticles } = useArticles();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!articles) {
      axios
        .get(`${SERVER_URL}/blog`)
        .then(({ data }) => {
          setArticles(data);
        })
        .catch(() => {
          // throw an alert error to user
          setError(true);
        });
    }
  }, [articles, setArticles]);

  if (!articles) {
    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} span={18}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20rem',
            }}>
            <Spin size="large" tip="Loading..." />
          </div>
        </Col>
      </Row>
    );
  }

  if (error) {
    return (
      <Row justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={24} span={18}>
          <Alert
            message="Sorry :("
            description="Blog posts seem to be unavailable right now. Kindly check later."
            type="error"
            showIcon
            closable
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <section className="blog-landing">
        <Row align="middle" justify="center">
          <Col>
            <Title level={2} style={{ color: '#fff', fontFamily: 'Roboto' }}>
              MWAKINA&apos;S BLOG
            </Title>
          </Col>
        </Row>
      </section>
      <section className="blog-list">
        <Row align="middle" justify="center">
          <Col xs={24} sm={20} md={20} lg={20} xl={20} span={4}>
            <List
              itemLayout="vertical"
              size="large"
              // pagination={{
              //   onChange: (page) => {
              //     console.log(page);
              //   },
              //   pageSize: 3,
              // }}
              dataSource={articles}
              renderItem={(item) => {
                // process the markdown for the text
                const { title } = item;
                const { article } = item;

                return (
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ display: 'flex' }}>
                    <Divider />
                    <List.Item
                      key={item.href}
                      // extra={<img width={300} alt="blog" src={item.image} />}
                    >
                      <List.Item.Meta
                        title={
                          <Link to={`/blog/${item.href}`}>
                            <Title level={2}>
                              <Markdown value={title} />
                            </Title>
                          </Link>
                        }
                        description={item.desc}
                      />
                      <Paragraph style={{ textAlign: 'left', margin: 0 }}>
                        <Markdown value={`${article.slice(0, 400)}}...`} />
                      </Paragraph>
                    </List.Item>
                  </Space>
                );
              }}
            />
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Blog;

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Space, Typography, Card, Row, Col, Alert } from 'antd';
import Markdown from 'marked-react';
import { useArticles } from './BlogContext';

const { Title, Paragraph, Text } = Typography;
const { Meta } = Card;

function Post() {
  const { href } = useParams();
  const navigate = useNavigate();
  const { articles } = useArticles();

  // find blog data from array of fetched blogs
  const newPost = articles.find((post) => post.href === href);

  // Alert error on close redirect back to homepage
  const onClose = () => {
    navigate('/');
  };

  if (!newPost) {
    return (
      <Alert
        message="Error Text"
        description="Sorry :( This page didn't load correctly"
        type="error"
        closable
        onClose={onClose}
      />
    );
  }

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={24} md={20} lg={18} xl={18}>
        <Space direction="vertical" size="middle">
          <Card style={{ padding: '0.5%', margin: '5%' }}>
            <Meta
              title={
                <Title>
                  <Markdown value={newPost.title} />
                </Title>
              }
              description={<Text type="secondary">{newPost.author}</Text>}
            />

            <Paragraph
              key={`${newPost.href}`}
              style={{
                whiteSpace: 'pre-wrap',
                margin: '3%',
                fontSize: '1.2rem',
                textAlign: 'left',
              }}>
              <Markdown value={newPost.article} />
            </Paragraph>
          </Card>
        </Space>
      </Col>
    </Row>
  );
}

export default Post;
